<template>
    <!--FORMULAR-->
    <section class="pa-4 px-2 formular career">
        <!--SAVE BUTTON TOOLBAR-->
        <div class="toolbar-buttons">
            <v-btn v-if="checkPrivileg && edit" dark small depressed text @click="submit('saveItem')">
                <v-icon left>mdi-content-save-outline</v-icon>
                <span>{{ $t('common.save') }}</span>
            </v-btn>
        </div>

        <!--FORMS-->
        <v-container v-if="checkPrivileg" fluid>
            <v-form @submit.prevent="submit" id="form" ref="form" class="formular">
                <!--BASIC DATA-->
                <v-row :class="{ 'rgg-grey' : isDark }" class="grey lighten-4 rounded mb-2">
                    <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                        {{ $t('users.dialog.basic_data') }}
                    </v-col>

                    <v-col cols="12">
                        <v-row>
                            <v-col v-bind="col_default" v-if="edit">
                                <v-text-field outlined dense color="gold"
                                              :label="$t('users.dialog.id')"
                                              v-model="customer_data.id"
                                              disabled
                                />
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-select outlined dense color="gold"
                                          :label="$t('users.dialog.type')"
                                          item-text="name"
                                          item-value="id"
                                          :items="person_types"
                                          v-model="customer_data.type"
                                          @change="checkType()"
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row>
                            <v-col v-bind="col_default">
                                <v-text-field outlined dense color="gold"
                                              :label="$t('users.dialog.title')"
                                              v-model="customer_data.degree"
                                />
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-text-field outlined dense color="gold"
                                              :label="$t('users.dialog.name')"
                                              v-model="registration_data.name"
                                              autocomplete="off"
                                              v-validate="'required'"
                                              data-vv-as="name"
                                              data-vv-name="user_name"
                                              :error-messages="errors.collect('user_name')"

                                >
                                    <template v-slot:prepend>
                                        <v-icon x-small color="red">mdi-asterisk</v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col v-bind="col_default">
                                <v-text-field outlined dense color="gold"
                                              :label="$t('users.dialog.surname')"
                                              v-model="registration_data.surname"
                                              autocomplete="off"
                                              v-validate="'required'"
                                              data-vv-as="surname"
                                              data-vv-name="user_surname"
                                              :error-messages="errors.collect('user_surname')"
                                >
                                    <template v-slot:prepend>
                                        <v-icon x-small color="red">mdi-asterisk</v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col cols="12">
                        <v-row>
                            <v-col v-bind="col_email">
                                <v-text-field outlined dense color="gold"
                                              :label="$t('users.dialog.private_email')"
                                              v-model="registration_data.email"
                                              autocomplete="off"
                                              v-validate="'required|email'"
                                              data-vv-name="user_private_email"
                                              :error-messages="errors.collect('user_private_email')"
                                >
                                    <template v-slot:prepend>
                                        <v-icon x-small color="red">mdi-asterisk</v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-row>
                            <v-col v-bind="col_default">
                                <v-text-field outlined dense color="gold"
                                              :label="$t('users.dialog.tel')"
                                              v-model="customer_data.phone"

                                              v-validate="'required'"
                                              data-vv-name="user_tel"
                                              :error-messages="errors.collect('user_tel')"
                                >
                                    <template v-slot:prepend>
                                        <v-icon x-small color="red">mdi-asterisk</v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <!--PERSONAL DATA-->
                <v-row :class="{ 'rgg-grey' : isDark }" class="grey lighten-4 rounded mb-2">
                    <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                        {{ $t('users.dialog.personal_data') }}
                    </v-col>

                    <v-col v-bind="col_default">
                        <v-menu
                            ref="menu_date_of_birth"
                            v-model="modal_date_of_birth"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="customer_data.dateOfBirth"
                                    :label="$t('users.dialog.date_of_birth')"
                                    prepend-icon="event"
                                    readonly
                                    v-on="on"
                                    outlined dense
                                    @click="$validator.validate('user_date_of_birth')"
                                    @focus="$validator.validate('user_date_of_birth')"
                                    color="gold"
                                    v-validate="'required|bornLimit'"
                                    data-vv-name="user_date_of_birth"
                                    :error-messages="errors.collect('user_date_of_birth')"
                                >
                                    <template v-slot:prepend>
                                        <v-icon x-small color="red">mdi-asterisk</v-icon>
                                    </template>
                                </v-text-field>
                            </template>
                            <v-date-picker
                                ref="picker"
                                v-model="customer_data.dateOfBirth"
                                @change="saveDateOfBirth"
                                locale="sk"
                                color="gold"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-text-field outlined dense color="gold" key="user_id_number-input"
                                      :label="$t('users.dialog.id_number')"
                                      v-model="customer_data.personalID"
                                      v-validate="'required'"
                                      data-vv-name="user_id_number"
                                      :error-messages="errors.collect('user_id_number')"
                        >
                            <template v-slot:prepend>
                                <v-icon x-small color="red">mdi-asterisk</v-icon>
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>

                <!--PERSON DATA-->
                <v-row v-if="isPerson" :class="{ 'rgg-grey' : isDark }" class="grey lighten-4 rounded mb-2">
                    <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                        {{ $t('users.dialog.company_data') }}
                    </v-col>

                    <v-col v-bind="col_default">
                        <v-text-field outlined dense color="gold" key="dic-input"
                                      :label="$t('users.dialog.dic')"
                                      v-model="customer_data.taxId"

                                      v-validate="!isPerson ? 'required' : ''"
                                      data-vv-name="dic"
                                      :error-messages="errors.collect('dic')"
                        />
                    </v-col>
                </v-row>
                <!--COMPANY DATA-->
                <v-row v-else :class="{ 'rgg-grey' : isDark }" class="grey lighten-4 rounded mb-2">
                    <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                        {{ $t('users.dialog.company_data') }}
                    </v-col>

                    <v-col v-bind="col_default">
                        <v-text-field outlined dense color="gold" key="user_company_name-input"
                                      :label="$t('users.dialog.company_name')"
                                      v-model="customer_data.companyName"

                                      v-validate="'required'"
                                      data-vv-name="user_company_name"
                                      :error-messages="errors.collect('user_company_name')"
                        >
                            <template v-slot:prepend>
                                <v-icon x-small color="red">mdi-asterisk</v-icon>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-text-field outlined dense color="gold" key="ico-input"
                                      :label="$t('users.dialog.ico')"
                                      v-model="customer_data.companyId"

                                      v-validate="'required'"
                                      data-vv-name="ico"
                                      :error-messages="errors.collect('ico')"
                        >
                            <template v-slot:prepend>
                                <v-icon x-small color="red">mdi-asterisk</v-icon>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-text-field outlined dense color="gold" key="dic-input"
                                      :label="$t('users.dialog.dic')"
                                      v-model="customer_data.taxId"

                                      v-validate="!isPerson ? 'required' : ''"
                                      data-vv-name="dic"
                                      :error-messages="errors.collect('dic')"
                        >
                            <template v-slot:prepend>
                                <v-icon x-small color="red">mdi-asterisk</v-icon>
                            </template>
                        </v-text-field>
                    </v-col>

                    <v-col v-bind="col_default">
                        <v-text-field outlined dense color="gold" key="ic_dph-input"
                                      :label="$t('users.dialog.ic_dph')"
                                      v-model="customer_data.vatId"
                        />
                    </v-col>
                </v-row>

                <!--ADDRESS-->
                <v-row :class="{ 'rgg-grey' : isDark }" class="grey lighten-4 rounded mb-2">
                    <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                        {{ $t('users.dialog.address') }}
                    </v-col>

                    <v-col v-bind="col_default">
                        <v-text-field outlined dense color="gold" key="user_street_and_number-input"
                                      :label="$t('users.dialog.street_and_number')"
                                      v-model="customer_data.mainAddress.street"
                                      @keyup="$validator.validate('user_street_and_number')"

                                      v-validate="'required'"
                                      data-vv-name="user_street_and_number"
                                      :error-messages="errors.collect('user_street_and_number')"
                        >
                            <template v-slot:prepend>
                                <v-icon x-small color="red">mdi-asterisk</v-icon>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-text-field outlined dense color="gold"
                                      :label="$t('users.dialog.city')"
                                      v-model="customer_data.mainAddress.city"
                                      @keyup="$validator.validate('user_city')"

                                      v-validate="'required'"
                                      data-vv-name="user_city"
                                      :error-messages="errors.collect('user_city')"
                        >
                            <template v-slot:prepend>
                                <v-icon x-small color="red">mdi-asterisk</v-icon>
                            </template>
                        </v-text-field>
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-text-field outlined dense color="gold"
                                      :label="$t('users.dialog.psc')"
                                      v-model="customer_data.mainAddress.zip"
                                      @keyup="$validator.validate('user_psc')"

                                      v-validate="'required'"
                                      data-vv-name="user_psc"
                                      :error-messages="errors.collect('user_psc')"
                        >
                            <template v-slot:prepend>
                                <v-icon x-small color="red">mdi-asterisk</v-icon>
                            </template>
                        </v-text-field>
                    </v-col>

                    <v-col v-bind="col_default">
                        <v-select outlined dense color="gold"
                                  :label="$t('users.dialog.country')"
                                  :items="getCountries"
                                  item-text="name"
                                  v-model="customer_data.mainAddress.country"
                        />
                    </v-col>
                </v-row>

                <!--AGREEMENT DATA-->
                <v-row :class="{ 'rgg-grey' : isDark }" class="grey lighten-4 rounded mb-2">
                    <v-col cols="12" class="headline gold--text text-uppercase font-weight-light">
                        {{ $t('users.dialog.agreement_data') }}
                    </v-col>
                    <v-col v-bind="col_default">
                        <v-text-field outlined dense color="gold"
                                      :label="$t('users.dialog.iban')"
                                      v-model="customer_data.bankAccount"
                        />
                    </v-col>

                </v-row>

            </v-form>
        </v-container>

        <!--BOTTOM BUTTONS-->
        <v-container fluid>
            <v-row align="center" justify="center" class="mt-4">
                <v-col cols="12" align="center" v-if="checkPrivileg && !edit">
                    <v-btn class="gold lighten-1 white--text mr-1" depressed small @click="submit">
                        {{ $t('common.registration') }}
                    </v-btn>
                    <v-btn class="white lighten-1 gold--text mr-1" depressed small outlined
                           @click="closeDialog">{{ $t('common.cancel') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>

    </section>
</template>

<script>
import countries from "@/assets/countries";
import AuthMixin from "@/services/auth/auth_mixin";
import Permissions from "@/services/auth/permissions";
import {checkEmail} from "@/api/auth";
import {
    createCustomer,
} from "@/api/customers";
import {Utils} from "@/services/utils";

export default {
    name: "CustomerDialog",
    props: {
        edit: Boolean,
        id: undefined,
        card_item: undefined
    },
    mixins: [AuthMixin],
    data: () => ({
        data_ready: false,

        person_types: [
            {id: 'PERSON', name: "Osoba"},
            {id: 'COMPANY', name: "Firma"},
            {id: 'SELF_EMPLOYED', name: "SZČO"}
        ],

        customer_data: {},
        registration_data: {
            name: '',
            surname: '',
            email: ''
        },

        modal_date_of_birth: false,
        modal_start_collaboration: false,
        isPerson: true,

        col_default: {cols: 12, sm: 6, md: 4, lg: 3},
        col_email: {cols: 12, sm: 12, md: 6, lg: 6},
    }),

    watch: {
        modal_date_of_birth(val) {
            val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
        },

        '$vuetify.breakpoint.width': function () {
            this.$parent.$emit('action', {func: 'setToolbarTitle', data: this.toolbar_title})
        },
    },

    computed: {
        isDark() {
            return this.$vuetify.theme.dark;
        },

        toolbar_title() {
            if (this.edit) {
                if (this.$vuetify.breakpoint.width < 451) {
                    return this.id;
                } else {
                    return this.customer_data.name + " " + this.customer_data.surname + " - " + this.id;
                }
            } else {
                return this.edit
            }
        },

        getCountries() {
            return countries.country_codes;
        },

        checkPrivileg() {
            if (this.checkPermission(Permissions.ADMINISTRATOR) || this.checkPermission(Permissions.SUPPORTER)) {
                return true;
            }

            return !this.edit;
        }
    },

    methods: {
        saveDateOfBirth() {
            this.$refs.menu_date_of_birth.save(this.customer_data.dateOfBirth)
            setTimeout(() => (this.$validator.validate('user_date_of_birth')))
        },

        checkType() {
            this.isPerson = this.customer_data.type === 'PERSON';
            this.validatorRefresh();
        },

        validatorRefresh() {
            this.$validator.pause();

            this.$nextTick(() => {
                this.$validator.errors.clear();
                this.$validator.fields.items.forEach(f => f.reset());
                this.$validator.fields.items.forEach(f => this.errors.remove(f));
                this.$validator.resume();
            });
        },

        closeDialog() {
            if (this.edit) {
                this.$parent.$emit('action', {func: 'close', data: null});
            } else {
                this.$parent.$emit('call', {func: 'close', data: null});
            }
        },

        reset() {
            this.customer_data = {};
        },

        submit() {
            this.$validator.validateAll().then(() => {
                this.$root.$emit('overlay', true);
                if (!this.$validator.errors.any()) {
                    this.save()
                } else {
                    this.$root.$emit('overlay', false);
                    this.$root.$emit('notification', {
                        type: 'error',
                        icon: 'info',
                        msg: 'notification.form_error'
                    });
                }
            })
        },

        save() {
            this.$root.$emit('overlay', true);

            let customerForm = {
                email: this.registration_data.email,

                name: this.registration_data.name,
                surname: this.registration_data.surname,
                degree: this.customer_data.degree,

                dateOfBirth: this.customer_data.dateOfBirth,
                personalID: this.customer_data.personalID,

                type: this.customer_data.type,

                companyId: this.customer_data.companyId,
                companyName: this.customer_data.companyName,
                taxId: this.customer_data.taxId,
                vatId: this.customer_data.vatId,

                phone: this.customer_data.phone,

                mainAddress: {
                    street: this.customer_data.mainAddress.street,
                    city: this.customer_data.mainAddress.city,
                    zip: this.customer_data.mainAddress.zip,
                    country: this.customer_data.mainAddress.country
                },

                bankAccount: this.customer_data.bankAccount,
            }

            if (this.customer_data.type === 'PERSON') {
                customerForm.companyId = ''
                customerForm.companyName = ''
                customerForm.vatId = ''
            }

            createCustomer(customerForm).then(() => {
                this.$root.$emit('overlay', false);
                this.closeDialog();
                this.$root.$emit('notification', {
                    type: 'success',
                    icon: 'update',
                    msg: 'customer.create_notify'
                })
            }).catch((e) => {
                this.$root.$emit('overlay', false);
                this.$root.$emit('notification', {
                    type: 'error',
                    icon: 'info',
                    msg: e
                });
            })
        },
    },

    created() {
        this.$validator.extend('checkExistingEmail', {
            validate: (value) => {
                return checkEmail(value)
                    .then(response => {
                        if (this.edit) {
                            return true
                        }
                        return !response.data;
                    })
            }
        });

        this.$validator.extend('checkInputs', {
            validate: (value, params) => {
                return this.registration_data[params[0]] !== '' && this.registration_data[params[1]] !== '';
            }
        });

        this.$validator.extend('bornLimit', {
            validate: (value) => {
                return Utils.is18YearsOld(value)
            }
        });

        this.customer_data.mainAddress = {
            city: "",
            country: "Slovensko",
            street: "",
            zip: ""
        };

        this.customer_data.boss = {};
        this.customer_data.position = 'P1';
        this.customer_data.type = 'PERSON';
        this.customer_data.phone = '+421';
        this.customer_data.contractStart = this.g_getDate();
        this.customer_data.status = [];

        this.validatorRefresh();
    },

    mounted() {
    },

    destroyed() {
        this.reset();
    },

    updated() {
        this.$parent.$emit('action', {func: 'setToolbarTitle', data: this.toolbar_title});
    }
}
</script>

<style>
.reader {
    background-color: rgba(201, 158, 68, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
}

#app.theme--dark .reader {
    background-color: rgba(0, 0, 0, 0.1);
}
</style>